import React from "react";
import { graphql } from "gatsby";
import Layout from "src/common/Layout";
import SEO from "src/common/components/seo";
import { NewsQuery } from "types/graphql-types";
import DownloadLink from "src/common/components/DownloadLink";
import ContentfulRichText from "src/common/components/ContentfulRichText";
import NewsDetailStage from "src/common/components/Stages/NewsDetailStage";
import { Grid, Hidden } from "@material-ui/core";
import styled from "styled-components";
import ContentWidthWrapper from "src/common/components/ContentWidthWrapper";
import BlogPost from "src/common/components/BlogPost";

const IndexPage = ({
  data: { contentfulNews, allContentfulNews, contentfulFooter, contentfulHeader },
  ...props
}: {
  data: NewsQuery;
  props: { pageContext: { slug: string } };
}) => {
  if (!contentfulNews || !contentfulFooter || !contentfulHeader) {
    throw new Error("Not all Contentful requirements met: contentfulNews,contentfulHeader, contentfulFooter");
  }

  const { title, description, creationDate, text, downloads } = contentfulNews;
  const { edges } = allContentfulNews;

  return (
    <Layout
      contentfulHeader={contentfulHeader}
      contentfulFooter={contentfulFooter}
      navigationIsDark
      stageContent={<NewsDetailStage title={title!} date={creationDate} />}
    >
      <SEO title={title!} description={description!} />
      <ContentWidthWrapper padding>
        <Grid container justify="space-between">
          {downloads && (
            <Hidden smDown>
              <DownloadWrapper item xs={12} md={5}>
                {downloads?.map((download: any) => (
                  <DownloadLink
                    href={download?.file?.url!}
                    fileName={download?.title!}
                    backgroundColor
                    key={download.title}
                  />
                ))}
              </DownloadWrapper>
            </Hidden>
          )}
          <ContentWrapper item xs={12} md={7}>
            <ContentfulRichText
              json={text!.json}
              entryLinkPrefix="/news"
              showInitial
            />
          </ContentWrapper>

          {downloads && (
            <Hidden mdUp>
              <DownloadWrapper item xs={12} md={5}>
                {downloads?.map((download: any) => (
                  <DownloadLink
                    href={download?.localFile?.publicURL!}
                    fileName={download?.title!}
                    backgroundColor
                    key={download.title}
                  />
                ))}
              </DownloadWrapper>
            </Hidden>
          )}

          <PostsWrapper container>
            {edges
              // @ts-ignore
              ?.filter(({ node }) => node.slug !== props.pageContext.slug)
              .slice(0, 2)
              .map(({ node }) => (
                <BlogPostWrapper item xs={12} md={6} key={node.id!}>
                  <BlogPost
                    date={node.creationDate}
                    title={node.title!}
                    description={node.description!.slice(0, 80) + "..."}
                    link={node.slug!}
                  />
                </BlogPostWrapper>
              ))}
          </PostsWrapper>
        </Grid>
      </ContentWidthWrapper>
    </Layout>
  );
};

export const query = graphql`
  query News($slug: String!, $locale:String!) {
    contentfulNews(slug: { eq: $slug }, node_locale: {eq: $locale}) {
      title
      description
      creationDate
      image {
        title
        file {
          url
        }
      }
      downloads {
        title
        file {
          url
          fileName
          contentType
        }
        localFile {
          publicURL
        }
      }
      childContentfulNewsTextRichTextNode {
        json
        text
      }
      text {
        json
      }
    }
    allContentfulNews(sort: { fields: creationDate, order: DESC }, limit: 3,filter: {node_locale: {eq: $locale}}) {
      edges {
        node {
          id
          title
          description
          creationDate
          slug
        }
      }
    }
    contentfulFooter (node_locale: {eq: $locale}){
      title
      text
      copyright
      columnOneTitle
      columnOneLinks {
        id
        label
        url
        type
      }
      columnTwoTitle
      columnTwoLinks {
        id
        label
        url
        type
      }
      columnThreeTitle
      columnThreeLinks {
        id
        label
        url
        type
      }
      instagramLink
      facebookLink
      pinterestLink
      columnFourTitle
      childContentfulFooterLanguagesListJsonNode {
        de
        en
      }
      chosenLang
      deGermanFlag {
        file {
          url
        }
      }
      enEnglishFlag {
        file {
          url
        }
      }
      chosenFlag {
        file {
          url
          fileName
        }
      }
    }
    contentfulHeader (node_locale: {eq: $locale}){
      links {
        id
        label
        url
        type
      }
    }
  }
`;

export default IndexPage;

const DownloadWrapper = styled(Grid)(
  ({ theme }) => `
  padding: ${theme.spacing(4, 0, 0, 0)};
  ${theme.breakpoints.up("sm")} {
    padding: ${theme.spacing(5, 7, 0, 0)};
  }
  ${theme.breakpoints.up("md")} {
    padding: ${theme.spacing(5, 7, 0, 0)};
  }
`
);

const ContentWrapper = styled(Grid)(
  ({ theme }) => `
  padding: ${theme.spacing(3, 0, 0, 0)};
  @media (min-width: 1280px) {
    padding-right: 0;
  }
`
);

const PostsWrapper = styled(Grid)(
  ({ theme }) => `
    margin-top: ${theme.spacing(6)}px;
    padding-top: ${theme.spacing(5)}px;
    border-top: 1px solid ${theme.palette.divider};
`
);

const BlogPostWrapper = styled(Grid)(
  ({ theme }) => `

    ${theme.breakpoints.up("md")}{
      &:first-of-type{
        padding-right: ${theme.spacing(3)}px;
      }
    }
`
);
