import React from "react";
import styled from "styled-components";
import { Typography, Grid } from "@material-ui/core";
import formatDate from "src/util/formatDate";
import ContentWidthWrapper from "../ContentWidthWrapper";

interface Props {
  title: string;
  date: Date;
}

const NewsDetailStage: React.FC<Props> = ({ title, date }: Props) => {
  return (
    <Wrapper>
      <ContentWidthWrapper padding>
        <ContentWrapper container>
          <TextWrapper item xs={12} sm={12} md={9} lg={7}>
            <Typography variant="h1" gutterBottom>
              {title}
            </Typography>
            <Typography variant="subtitle1">{formatDate(date)}</Typography>
          </TextWrapper>
        </ContentWrapper>
      </ContentWidthWrapper>
    </Wrapper>
  );
};

export default NewsDetailStage;

const Wrapper = styled("div")(
  ({ theme }) => `
  padding: ${theme.spacing(10, 0, 0)};

  background-color: ${theme.palette.dust.main};
  ${theme.breakpoints.up("sm")} {
    padding: ${theme.spacing(15, 0, 0)};
  }
`
);

const ContentWrapper = styled(Grid)(
  ({ theme }) => `

`
);

const TextWrapper = styled(Grid)(
  ({ theme }) => `
  padding: ${theme.spacing(3, 2, 3, 0)};
`
);
